var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "virtual-scroll",
        {
          attrs: { data: _vm.list2, "item-size": 62, "key-prop": "id" },
          on: { change: (renderData) => (_vm.virtualList2 = renderData) },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "loadmore-table",
              class: _vm.loading ? "no-empty" : "",
              attrs: {
                "row-key": "id",
                stripe: "",
                data: _vm.virtualList2,
                height: "500px",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "id", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "内容", prop: "text", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "详情", width: "300px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                row.show = !row.show
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.show ? "隐藏" : "显示"))]
                        ),
                        row.show
                          ? _c("div", [_vm._v(_vm._s(row.text2))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c("el-table-column", {
                attrs: { label: "第n行", prop: "id", width: "300px" },
              }),
              _c(
                "div",
                { attrs: { slot: "append" }, slot: "append" },
                [
                  _c("InfiniteLoading", {
                    attrs: {
                      forceUseInfiniteWrapper:
                        ".loadmore-table .el-table__body-wrapper",
                    },
                    on: { infinite: _vm.loadMore },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }